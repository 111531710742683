import React from "react";
import "/src/assets/scss/components/heros.scss";

function HeroContact() {
  return (
    <div className="hero hero-contact">
      <h1 className="title-1">CONTACT US</h1>
    </div>
  );
}

export default HeroContact;
